// src/pages/_app.tsx
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import type { CSSVariablesResolver } from '@mantine/core';
import { InputWrapper, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { env } from 'env/client.mjs';
import { useReloadOnTimeout } from 'hooks/misc/use-reload-on-timeout';
import type { AppType } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { Session } from 'next-auth';
import { getSession, SessionProvider } from 'next-auth/react';

import { LangProvider } from 'components/lang/LangContext';
import { MessageCenter } from 'components/messages/MessageCenter';
import { RouterTransition } from 'components/ui/RouterTransition/RouterTransition';
import { FeatureFlagsProvider } from 'components/utils/FeatureFlags';
import { useUserUpdateActivity } from 'utils/api/user/use-user-update-activity';
import { logger } from 'utils/logger';
import { trpc } from 'utils/trpc';
import { flattenParam } from 'utils/url-params';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/code-highlight/styles.css';
import 'mantine-datatable/styles.layer.css';
import './_app.css';
import classes from './_app.module.css';

if (
  env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
  env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'front',
    env: env.NEXT_PUBLIC_BP_ENV,
    // todo remove vercel value once on railway
    version: (
      env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
      env.NEXT_PUBLIC_GIT_COMMIT_SHA ||
      ''
    ).substring(0, 10),
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  if (env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED === 'true') {
    datadogRum.startSessionReplayRecording();
  }
}

const resolver: CSSVariablesResolver = () => ({
  // Bold text might look blurry on macOS otherwise
  variables: {
    '--mantine-webkit-font-smoothing': 'auto',
  },
  dark: {},
  light: {},
});

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  // Refresh page every 24h
  useReloadOnTimeout(86400);

  // We need to know when the router fields are updated client-side so token procedures don't throw
  const { query, isReady, pathname } = useRouter();
  const token = flattenParam(query['token']);

  const isAuthOrOnboardingPage =
    pathname.includes('/auth/') || pathname.includes('/onboarding');

  const isExternalView = pathname.includes('/external');

  // Register actual latest user activity on page load
  const { mutate: updateUserActivity } = useUserUpdateActivity();
  useEffect(() => {
    isReady && !token && !isAuthOrOnboardingPage && updateUserActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  // Define user properties in Datadog RUM
  useEffect(() => {
    const setRUMSession = async () => {
      const data = await getSession();
      datadogRum.setUser({
        id: data?.user?.id,
        email: data?.user?.email || undefined,
        name: data?.user?.name || undefined,
        org: data?.user?.organization?.name || undefined,
        orgId: data?.user?.organizationId || undefined,
      });
    };
    setRUMSession().catch(() => {
      logger.error('Failed to get session');
    });
  }, []);

  const isInWidget = pathname.startsWith('/external');

  return (
    <SessionProvider session={session}>
      <LangProvider>
        {env.NEXT_PUBLIC_BP_ENV === 'production' && (
          <>
            <Script id="pendo">
              {`(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('62246d00-8912-4483-53a1-8d86d0afb7ee');`}
            </Script>
            <Script
              src="https://widget.freshworks.com/widgets/150000002587.js"
              async
              defer
            ></Script>
          </>
        )}
        <Head>
          <title>BiggerPicture</title>
          <meta property="og:title" content="BiggerPicture" key="title" />
        </Head>
        <MantineProvider
          cssVariablesResolver={resolver}
          theme={{
            cursorType: 'pointer',
            colors: {
              'bp-orange': [
                '#fff4e6',
                '#ffe8cc',
                '#ffd8a8',
                '#ffc078',
                '#ffa94d',
                '#ff922b',
                '#ff4410', // Note: This is our brand color. The rest are placeholders.
                '#f76707',
                '#e8590c',
                '#d9480f',
              ],
            },
            components: {
              AppShell: {
                defaultProps: {
                  padding: 'xl',
                },
                styles: {
                  root: {
                    position: 'relative',
                  },
                  main: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  },
                },
              },
              Checkbox: {
                defaultProps: {
                  size: 'xs',
                },
                styles: {
                  input: {
                    cursor: 'pointer',
                  },
                  label: {
                    cursor: 'pointer',
                  },
                },
              },
              Tooltip: {
                defaultProps: {
                  position: 'top',
                  withArrow: true,
                  withinPortal: true,
                  arrowSize: 10,
                  arrowRadius: 2,
                  zIndex: 9999, // To be above antd modals
                },
              },
              Badge: {
                defaultProps: {
                  radius: 'sm',
                  variant: 'filled',
                },
                styles: {
                  root: {
                    height: '24px',
                    fontWeight: 'initial',
                    textTransform: 'none',
                    letterSpacing: 'normal',
                    fontSize: '14px',
                    paddingInline: '7px',
                  },
                },
              },
              Tabs: {
                defaultProps: {
                  variant: 'outline',
                },
                styles: {
                  tabsList: {
                    paddingLeft: 16,
                    borderBottomColor: '#f0f0f0',
                  },
                },
              },
              Popover: {
                defaultProps: {
                  withArrow: true,
                  withinPortal: true,
                  radius: 'md',
                  shadow: 'lg',
                  arrowSize: 12,
                  zIndex: 199,
                },
              },
              Group: {
                defaultProps: {
                  spacing: 'xs',
                },
              },
              Text: {
                defaultProps: {
                  size: 'sm',
                },
              },
              Modal: {
                defaultProps: {
                  radius: 'md',
                  size: 520,
                  yOffset: '12vh',
                  centered: isInWidget,
                },
                styles: {
                  content: {
                    fontSize: 14,
                    minWidth: isExternalView ? 300 : 520,
                  },
                  header: {
                    paddingBottom: '0.5rem',
                  },
                  title: {
                    fontWeight: 'bold',
                  },
                },
              },
              Divider: {
                defaultProps: {
                  color: 'gray.2',
                },
              },
              Stepper: {
                styles: {
                  separator: {
                    minWidth: '50px',
                  },
                },
              },
              Combobox: {
                defaultProps: {
                  zIndex: 205, // Above Modals
                  withArrow: false,
                },
                classNames: {
                  option: classes.comboboxOption,
                },
              },
              InputLabel: {
                defaultProps: {
                  mb: 2,
                },
              },
              InputWrapper: InputWrapper.extend({
                classNames: classes,
              }),
            },
          }}
        >
          <MessageCenter />
          <DatesProvider settings={{ firstDayOfWeek: 0 }}>
            <FeatureFlagsProvider>
              <ModalsProvider>
                <Notifications
                  position={isExternalView ? 'top-center' : 'bottom-right'}
                />
                <RouterTransition />
                <Component {...pageProps} />
              </ModalsProvider>
            </FeatureFlagsProvider>
          </DatesProvider>
        </MantineProvider>
        {env.NEXT_PUBLIC_BP_ENV === 'production' && (
          <>
            <Script
              async
              id="googleAnalytics"
              src="https://www.googletagmanager.com/gtag/js?id=G-0Y05B7E5B5"
            ></Script>
            <Script id="ga">{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-0Y05B7E5B5');
          `}</Script>
          </>
        )}
      </LangProvider>
    </SessionProvider>
  );
};

export default trpc.withTRPC(MyApp);
